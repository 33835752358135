import { NewFile } from "../NewFile";

export function HomeHeroSectionBasic() {
  return (
    <div
      className={`flex items-stretch flex-col md:flex-row bg-[rgb(56,52,139)] lg:p-6 lg:pb-16 pt-20 lg:pt-32 ml-auto mr-auto`}
    >
      <div className="self-center flex px-6 pb-8 mt-8 lg:mt-0n max-w-3xl mr-auto ml-auto">
        <div className="self-center flex w-3xl mr-auto ml-auto">
          <NewFile />
        </div>
      </div>
    </div>
  );
}
